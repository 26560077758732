<template>
  <header class="header">
    <div class="header-content">
      <h1 class="logo">chromehearts online drops</h1>
      <!-- <nav class="navigation">
        <ul class="nav-list">
          <li><a href="#features" class="nav-link">Features</a></li>
          <li><a href="#pricing" class="nav-link">Pricing</a></li>
          <li><a href="#about" class="nav-link">About Us</a></li>
        </ul>
      </nav> -->
      <!-- <div class="cta-container">
      </div> -->
    </div>
  </header>
</template>

<script>
export default {
  name: 'ChromeHeartsHeader',
}
</script>

<style scoped>
.header {
  background-color: #fafafa;
  padding: 19px 72px 0;
  width: 100%;
}

.header-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.logo {
  color: #18181b;
  font: 800 1em/187.5% 'Plus Jakarta Sans', sans-serif;
  letter-spacing: 3px;
  text-transform: uppercase;
  margin: auto 0;
  padding-left: 15px; /* Add padding to the left */
}

/* .navigation {
  margin: auto 0;
} */

/* .nav-list {
  display: flex;
  gap: 20px;
  list-style-type: none;
  padding: 0;
} */

/* .nav-link {
  color: #18181b;
  font: 500 1em/150% 'Plus Jakarta Sans', sans-serif;
  text-decoration: none;
} */

/* .cta-container {
  display: flex;
  gap: 20px;
} */

@media (max-width: 991px) {
  .header {
    padding: 0 20px;
  }

  .header-content {
    flex-wrap: wrap;
  }

}
</style>