<template>
  <div class="chrome-hearts-page">
    <ChromeHeartsHeader />
    <ChromeHeartsContent :redirectToSuccess="redirectToSuccess"/>
    <!-- <button @click="redirectToSuccess">Test Success Page</button> test success page button -->
  </div>
</template>

<script>
import ChromeHeartsHeader from './ChromeHeartsHeader.vue';
import ChromeHeartsContent from './ChromeHeartsContent.vue';

export default {
  name: 'ChromeHeartsPage',
  components: {
    ChromeHeartsHeader,
    ChromeHeartsContent
  },
  props: {
    redirectToSuccess: {
      type: Function,
      required: true
    }
  }
}
</script>

<style scoped>
.chrome-hearts-page {
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100vh;
  overflow-x: hidden; 
}

@media (max-width: 991px) {
  .chrome-hearts-page {
    max-width: 100%;
  }
}
</style>
