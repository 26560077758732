<template>
  <div id="app">
    <component :is="currentPage" :redirectToSuccess="redirectToSuccess" />
  </div>
</template>

<script>
import ChromeHeartsPage from './components/ChromeHeartsPage.vue';
import ChromeHeartsSuccessPage from './components/ChromeHeartsSuccessPage.vue';

export default {
  name: 'App',
  components: {
    ChromeHeartsPage,
    ChromeHeartsSuccessPage
  },
  data() {
    return {
      currentPage: 'ChromeHeartsPage'
    }
  },
  methods: {
    redirectToSuccess() {
      this.currentPage = 'ChromeHeartsSuccessPage';
    }
  }
}
</script>

<style>
@import './assets/styles.css';
</style>

<!-- <template>
  <div id="app">
    <ChromeHeartsPage />
  </div>
</template>

<script>
import ChromeHeartsPage from './components/ChromeHeartsPage.vue';

export default {
  name: 'App',
  components: {
    ChromeHeartsPage
  }
}
</script>

<style>
@import './assets/styles.css';
</style> -->
