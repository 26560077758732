<!-- ChromeHeartsSuccessPage.vue -->
<template>
    <main class="success-page">
      <section class="success-content">
        <img src="../assets/success.png" alt="Success" class="success-image"/>
        <h2 class="success-title">Subscription Successful!</h2>
        <p class="success-message">Thank you for subscribing to ChromeHearts Online Drops. <br/> You'll receive notifications for new drops directly in your inbox.</p>
        <a href="/" class="home-link">Go back to Home</a>
      </section>
    </main>
  </template>
  
  <script>
  export default {
    name: 'ChromeHeartsSuccessPage'
  }
  </script>
  
  <style scoped>
  .success-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    text-align: center;
  }
  
  .success-image {
    width: 300px;
    height: 300px;
    margin-bottom: 20px;
  }
  
  .success-title {
    color: #38a169;
    font: 800 1.5em/1.5 'Plus Jakarta Sans', sans-serif;
    margin-bottom: 10px;
  }
  
  .success-message {
    color: #52525b;
    font: 400 1em/1.5 'Inter', sans-serif;
    margin-bottom: 20px;
  }
  
  .home-link {
    color: #004bdd;
    text-decoration: none;
    font-weight: bold;
  }
  </style>
  