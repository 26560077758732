<template>
    <main class="product-image">
      <header class="notifier-header">
        <h1 class="notifier-title">A service made for notifying about ChromeHearts online drops</h1>
      </header>
      <section class="notifier-content">
        <h2 class="notifier-subtitle">
          Secure your ChromeHearts drop <span class="gradient-text">using our service</span>
        </h2>
        <p class="notifier-pricing">$1 / Month | No software required</p>
        <div id="paypal-button-container" class="paypal-button"></div>
      </section>
      <img 
        src="https://cdn.builder.io/api/v1/image/assets%2F13bc30adf70741e985ce4419497cb106%2Fc43557f5209749d7bbeadef281086818" 
        alt="Circle product features" 
        class="features-image"
      />
      <section id="about" class="notifier-pricing">
      <h2> <span class="gradient-text">About Us</span></h2>
      <p class="notifier-title">We provide a notification service for ChromeHearts online drops. Our goal is to help you secure your desired items with ease by sending you an email when a new drop takes place. For any inquiries, please contact us at: <a href="mailto:ch.online.drop.cs@gmail.com">ch.online.drop.cs@gmail.com</a></p>
      <a href="https://www.instagram.com/chonlinedrops" target="_blank" rel="noopener noreferrer">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="instagram-icon"><rect x="2" y="2" width="20" height="20" rx="5" ry="5"></rect><path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path><line x1="17.5" y1="6.5" x2="17.51" y2="6.5"></line></svg>
      </a>
      </section>
      <footer id="about" class="notifier-pricing">
      <h2> <span class="footer">&copy; 2024 ChromeHearts Online Drops.</span></h2>

      </footer>
    </main>
  </template>
  
  <script>
  export default {
    name: 'ChromeHeartsContent',
    props: {
      redirectToSuccess: {
      type: Function,
      required: true
      }
    },
    mounted() {
      const script = document.createElement('script');
      script.src = "https://www.paypal.com/sdk/js?client-id=AYOZjQtV-qPaf9OigqDmVAAPuempM3DfF5tran8FbfupTZb60OP2yzGOi2YHMzNhM_IpcnHB66sI45hT&vault=true&intent=subscription";
      script.onload = this.loadPayPalButton;
      document.body.appendChild(script);
    },
    methods: {
      loadPayPalButton() {
        const self = this;
        // eslint-disable-next-line no-undef
        paypal.Buttons({
          createSubscription: function(data, actions) {
            return actions.subscription.create({
              'plan_id': 'P-9CF606053Y9909526M3R6OUA'
            });
          },
          onApprove: function(data, actions) {
            // Fetch the buyer's information
            actions.subscription.get().then(function(details) {
              const firstName = details.subscriber.name.given_name;
              const lastName = details.subscriber.name.surname;
              const email = details.subscriber.email_address;
  
              // Send a POST request to your backend
              fetch('https://chrome-hearts.onrender.com/new-subscription', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                  subscription_id: data.subscriptionID,
                  email: email,
                  first_name: firstName,
                  last_name: lastName
                })
              })
              .then(response => response.json())
              .then(data => {
                console.log('Subscription created successfully:', data);
                self.redirectToSuccess(); // Redirect to success component after successful subscription
              })
              .catch((error) => {
                console.error('Error:', error);
              });
            });
          }
        }).render('#paypal-button-container');
      }
    }
  }
  </script>
  
  <style scoped>
  .product-image {
    aspect-ratio: 1.96;
    width: 627px;
    border-radius: 10px;
    margin-top: 88px;
    max-width: 100%;
    text-align: center; /* Center the image */
  }
  
  .paypal-button {
    max-width: 500px; 
    margin: 0 auto;  
  }

  .features-image {
    aspect-ratio: 1.64;
    width: 100%; /* Make the image full width */
    height: auto; /* Maintain aspect ratio */
    z-index: 10;
    margin: 68px 0; /* Adjust margins */
    max-width: none; /* Override max-width */
    position: relative; /* To use z-index */
  }
  
  @media (max-width: 991px) {
    .product-image {
      margin-top: 40px;
    }
  
    .features-image {
      margin: 40px auto; /* Center the image */
    }
  }
  
  .notifier-container {
    display: flex;
    width: 573px;
    max-width: 100%;
    flex-direction: column;
    align-items: center;
    margin: 99px 0 256px;
  }
  
  .notifier-header {
    width: 100%;
  }
  
  .instagram-icon {
   margin-top: 10px;
   width: 24px;
   height: 24px;
   fill: #f5f7fa; /* Change this color as needed */
   display: inline-block;
}

  .notifier-title {
    color: #52525b;
    font: 400 20px/1.56 Inter, sans-serif;
    text-align: center;
  }
  
  .notifier-content {
    width: 100%;
    text-align: center;
  }
  
  .notifier-subtitle {
    margin-top: 36px;
    font: 800 52px/1.19 'Plus Jakarta Sans', -apple-system, Roboto, Helvetica, sans-serif;
  }
  
  .gradient-text {
    background: linear-gradient(92deg, #ff00b8 67.57%, #004bdd 89.21%, #8f00ff 109.27%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .notifier-pricing {
    color: #71717a;
    margin-top: 46px;
    font: 26px/1.625 Inter, sans-serif;
  }

  .footer {
  color: #71717a;
  margin-top: 26px;
  font: 16px/1.625 Inter, sans-serif;
  line-height: 1.4; /* Adjust line height for better spacing */
  text-align: center; /* Center the text */
  padding: 0 15px; /* Add padding to ensure the text isn't too close to the edges */
  }
  
  @media (max-width: 991px) {
    .notifier-container {
      margin: 40px 0;
    }
  
    .notifier-title {
      max-width: 100%;
      padding: 1px;
    }
  
    .notifier-subtitle {
      max-width: 100%;
      font-size: 40px;
      line-height: 1.325;
    }
  
    .notifier-pricing {
      margin-top: 40px;
    }

    .footer {
      margin-top: 40px;    
    }

    .paypal-button {
    max-width: 350px; 
    margin: 0 auto;  
  }

  }
  </style>
  